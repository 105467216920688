import axios from "axios";
import { requestInterceptors } from "./interceptors-request";
import { responseInterceptors } from "./interceptors-response";
import messageErrorHint from "./message-error-hint";
import { baseConfig } from "../config";

const server = axios.create({
    baseURL: baseConfig.baseURL, // 默认baseURL
    timeout: 80000, // 请求超时时间
    withCredentials: true // 表示跨域请求时是否需要使用凭证
});

server.interceptors.request.use(requestInterceptors, error => {
    messageErrorHint(error, "客户端响应失败");
    return Promise.reject(error);
});


server.interceptors.response.use(responseInterceptors, error => {
    messageErrorHint(error, "服务器响应失败");
    return Promise.reject(error);
});


export default server;

