<template>
    <section class="r-e-table">
        <div class="table-limit-height" :style="{ height: tableHeight }">
            <el-table
                    :data="data || tableData"
                    v-bind="$attrs"
                    height="100%"
                    max-height="100%"
                    ref="tableRef"
                    v-loading="loading"
                    style="width: 100%"
                    :row-key="rowKey"
                    :tree-props="treeProps"
                    :span-method="handleSpanMethod"
                    header-row-class-name="default-table-header-name"
                    @row-click="rowClick"
                    @current-change="currentChange"
                    @selection-change="handleSelectionChange"
                    @select="select"
                    @select-all="selectAll"
                    @sort-change="handleSortChange"
                    @cell-click="handleCellClick"
            >
                <slot name="expand"></slot>
                <slot name="selection"></slot>
                <slot name="index"></slot>
                <table-column
                        v-for="(col, i) in columns"
                        :key="col.prop || i"
                        :column="col"
                        :index="col.prop || i"
                >
                </table-column>
                <slot/>
                <slot name="customColumn"></slot>
                <slot name="toolbar"></slot>
                <template slot="empty">
                    <slot name="empty"></slot>
                </template>
                <template slot="append">
                    <slot name="append"></slot>
                </template>
            </el-table>
        </div>
        <el-pagination
                v-if="showPagination"
                :small="small"
                :current-page="pNumber"
                :page-sizes="pSizes"
                :page-size="pSize"
                :pager-count="pagerCount"
                :layout="pLayout"
                :total="total"
                @size-change="sizeChange"
                @current-change="pageChange"
        >
        </el-pagination>
    </section>
</template>

<script>
    import _ from "lodash";
    import TableColumn from "./TableColumn";
    import {VwVh} from "@custom/widthHeight";

    export default {
        props: {
            // 列
            columns: {
                type: Array,
                default: () => [],
            },
            // 静态表格数据源
            data: Array,
            // 后端返回资源请求
            dataRequest: Function,
            height: {
                type: Number,
                default: 300
            },
            query: {
                type: Object,
                default: () => ({}),
            },
            treeProps: {
                type: Object,
                default: () => {
                    return {
                        children: "children",
                        hasChildren: "hasChildren"
                    };
                },
            },
            // 是否不自动查询
            notAuto: {
                type: Boolean,
                default: false,
            },
            // 合并行或列的计算方法
            spanMethod: Function,
            // 数据预处理
            pretreat: Function,
            // 请求完成的回调
            afterDataReq: Function,
            // 是否显示页码
            showPagination: {
                type: Boolean,
                default: true,
            },
            pageSize: {         // 每页显示条目个数，支持 .sync 修饰符
                type: Number,
                default: 50,
            },
            pagerCount: {         // 页码按钮的数量，当总页数超过该值时会折叠
                type: Number,
                default: 7,
            },
            pageSizes: {        // 每页显示个数选择器的选项设置
                type: Array,
                default: () => [10, 50, 100, 1000, 10000],
            },
            pageLayout: {        // 每页显示个数选择器的选项设置
                type: String,
                default: "total, sizes, prev, pager, next, jumper",
            },
            small: {        // 分页器尺寸
                type: Boolean,
                default: false,
            },
            showLoading: {
                type: Boolean,
                default: true,
            },
            showCheckbox: {
                type: Boolean,
                default: false,
            },
            rowKeyId: {
                type: String,
                default: "uuid",
            },
        },
        name: "r-e-table",
        data() {
            return {
                tableData: [],
                loading: false,
                pNumber: 1,
                total: 0,
                pSize: this.pageSize,
                pSizes: this.pageSizes,
                pLayout: this.pageLayout,
                checkAll: false,
            };
        },
        components: {TableColumn},
        computed: {
            tableHeight() {
                // 生成自适应表格高度
                const vwVh = new VwVh();
                return vwVh.VH(this.height);
            }
        },
        watch: {
            tableData: {
                handler() {
                    // 数据变化，重新调整表格的布局
                    this.doLayout();
                },
                deep: true,
            },
            columns: {
                handler() {
                    // 数据变化，重新调整表格的布局
                    this.doLayout();
                },
                deep: true,
            },
        },
        created() {
            // 获取数据；判断是否自动获取数据
            if (this.notAuto || this.data) this.loading = false;
            else this.getTableData();
        },
        methods: {
            getTableData(isSearch = true) {
                // isSearch 获取数据的方式
                //       true： 搜索获取，初始获取；默认pNumber 为1，第一页
                //       false：分页获取，pNumber为当前选择页数

                // 深拷贝
                let params = _.cloneDeep(this.query);

                // 深拷贝
                if (this.showPagination) {
                    const pagination = {
                        page: isSearch ? 1 : this.pNumber,
                        pageSize: this.pSize,
                    };
                    Object.assign(params, pagination);
                }
                this.loading = this.showLoading;
                console.log(params)
                this.dataRequest(params)
                    .then((res) => {
                        let {list, page} = res || {list: []};
                        if (this.showPagination) {
                            // 带分页
                            const {totalNum} = page || 0;
                            this.total = totalNum || list.length || 0;
                            this.pNumber = page?page.page : 1;
                        }
                        if (this.pretreat) list = this.pretreat(list);
                        this.tableData = list;
                      console.log(this.tableData,'tableDatatableDatatableDatatableDatatableDatatableData')
                        this.afterDataReq && this.afterDataReq(list);
                    })
                    .catch((err) => {
                        console.log(err)
                        console.error("获取表格数据报错", err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            pageChange(value) {
                // 切页
                this.pNumber = value;
                if (!this.data) this.getTableData(false);
            },
            sizeChange(value) {
                // 改变每页个数
                this.pNumber = 1;
                this.pSize = value;
                if (!this.data) this.getTableData(false);
            },
            rowClick(row, column, event) {
                // 当某一行被点击时会触发该事件
                this.$emit("row-click", row, column, event);
            },
            currentChange(currentRow, oldCurrentRow) {
                // 单选 当前行发生变化触发该事件
                this.$emit("current-change", currentRow, oldCurrentRow);
            },
            handleSelectionChange(val) {
                // 当选择项发生变化时会触发该事件
                this.$emit("selection-change", val);
            },
            select(selection, row) {
                // 当用户手动勾选数据行的 Checkbox 时触发的事件
                this.$emit("select", selection, row);
            },
            selectAll(selection) {
                // 当用户手动勾选全选 Checkbox 时触发的事件
                this.$emit("select-all", selection);
            },
            setToggleRowSelection(tableData, selected) {
                // 回显复选
                for (const item of tableData) {
                    this.$refs.tableRef.toggleRowSelection(item, selected);
                }
            },
            setCurrentRow(row) {
                // 设置选择行，且高亮
                this.$refs.tableRef.setCurrentRow(row);
            },
            clearTableData() {
                // 情况表格数据
                this.tableData = [];
            },
            clearSort() {
                // 情况排序
                this.$refs.tableRef.clearSort();
            },
            rowKey(row) {
                // 行的UUID
                return row.id || row[this.rowKeyId];
            },
            handleSpanMethod({row, column, rowIndex, columnIndex}) {
                if (this.$parent.spanMethod) {
                    return this.$parent.spanMethod({row, column, rowIndex, columnIndex});
                }
            },
            handleSortChange({column, prop, order}) {
                // 当表格的排序条件发生变化的时候会触发该事件
                this.$emit("sort-change", {column, prop, order});
            },
            handleCellClick(row, column, cell, event) {
                // 当某个单元格被点击时会触发该事件
                this.$emit("cell-click", {row, column, cell, event});
            },
            doLayout() {
                // 重新布局表格
                this.$nextTick(() => {
                    this.$refs.tableRef && this.$refs.tableRef.doLayout();
                });
            },
            tableScrollMove(index = 0) {
                // 定位表格行
                const refName = "tableRef";
                // 不存在表格的ref vm 则返回
                if (!refName || !this.$refs[refName]) return;
                let vmEl = this.$refs[refName].$el;
                if (!vmEl) return;
                // 计算滚动条的位置
                const targetTop = vmEl.querySelectorAll(".el-table__body tr")[index].getBoundingClientRect().top;
                const containerTop = vmEl.querySelector(".el-table__body").getBoundingClientRect().top;
                const scrollParent = vmEl.querySelector(".el-table__body-wrapper");
                scrollParent.scrollTop = targetTop - containerTop;
            },
            onHandle(row) {
                this.$emit("on-handle", row);
            },
            clearSelection() {
                this.$refs["tableRef"].clearSelection();
            },
            toggleRowSelection(row, selected = null){
                if(selected) this.$refs["tableRef"].toggleRowSelection(row,selected);
                else this.$refs["tableRef"].toggleRowSelection(row);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .r-e-table {
        width: 100%;
        height: 100%;
        padding-bottom: VH(10px);

        .el-pagination {
            width: 100%;
            text-align: right;
            margin-top: VH(10px);
            box-sizing: border-box;
        }

        /deep/ .el-table .default-table-header-name th {
            color: #80A0B1;
            background-color: #FAFAFA;
        }

        /deep/ .el-table {
            .cell {
                font-size: rem(12px);
            }

            tr th, tr td {
                padding: VH(10px) 0;

                &:nth-of-type(1) {
                    padding-left: VW(10px);
                }
            }
        }
    }

    /* 用于树形结构数据对齐，设置列class = column-tree  */
    /deep/ .el-table__row:not([class*='el-table__row--level-']) {
        td.column-tree {
            padding-left: VW(20px);
        }
    }
</style>
