import request from "@http";

// 新增小区
export function addCommunityApi(data) {
    return request({
        method: "PUT",
        url: "api/gzf/baseinfo/community/",
        data,
    });
}

// 编辑小区
export function editCommunityApi(data) {
    return request({
        method: "put",
        url: `api/gzf/baseinfo/community//${data.uuid}`,
        data,
    });
}

// 获取小区+楼栋+单元列表
export function getCommunityListApi(params) {
    return request({
        method: "GET",
        url: "api/gzf/build/all",
        params,
    });
}

// 添加楼幢、单元
export function addBuildingApi(data) {
    return request({
        method: "PUT",
        url: "api/gzf/build/create",
        data,
    });
}
// 修改楼幢、单元
export function editBuildingApi(data) {
    return request({
        method: "PUT",
        url: "api/gzf/build/update",
        data,
    });
}
// 2022/12/23 王江毅 获取当前所有行政区
export function getAddressList() {
    return request({
        method: "GET",
        url: "/api/gzf/baseinfo/community/address/list",
    });
}

// 2023/01/16 王江毅 保租房小区设定默认金额折扣
export function communityDefaultAmount(params) {
    return request({
        method: "GET",
        url: "/api/gzf/build/community/default/amount/update",
        params
    });
}

// 2023/01/17 王江毅 小区房源导出
export function communityAllExport(params) {
    return request({
        method: "GET",
        url: "/api/gzf/baseinfo/community/export/apartments",
        responseType: 'blob',
        params
    });
}
// 2024-1-8-陈守亮-发票页面
export function communityAllrecoredt(params) {
    return request({
        method: "GET",
        url: "/api/invoice/billing/list",
        params
    });
}

// 2024-1-8-陈守亮-发票页面--详情
export function Invoicereview(uuid) {
    return request({
        method: "GET",
        url: `/api/invoice/details/${uuid}`,
    });
}




// 2024-1-8-陈守亮-发票审核--详情
export function Invoicereviewbtn(uuid,params) {
    return request({
        method: "get",
        // url: `/api/invoice/audit/${uuid}`,
        url:`/api/invoice/audit/open/${uuid}`,
        params
    });
}


// 2024-2-2-查看详情-陈守亮
export function billingStatuslookapi(params) {
    return request({
        method: 'GET',
        url: `/api/invoice/view/document`,
        params
    });
}


export function communityAllrecoredtlook(params) {
    return request({
        method: "GET",
        url: "/api/system/verify/rent/discounts",
        params
    });
}
export function communityAllrecoredtlookbtn(params) {
    return request({
        method: "GET",
        url: "/api/system/verify/rent/discounts/echo",
        params
    });
}
























































