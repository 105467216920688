<template>
    <section class="side-bar">
        <div class="side-bar-header">
            <img style="border-radius:50%" class="logo" src="../../assets/images/login/logo.png" alt="">
            <img class="name" src="../../assets/images/layout/name.png" alt="">
        </div>
        <div class="side-bar-menu">
            <el-menu
                class="menu-class"
                :default-active="activeMenu"
                unique-opened
                background-color="transparent"
                text-color="#fff"
                active-text-color="#fff"
                mode="vertical"
                router
            >
                <template v-for="item in menuList">
                    <el-menu-item
                        v-if="!item.children || item.children.length === 0"
                        :index="item.url"
                        :key="item.id"
                        @click="menuItemClick(item)"
                    >
                        <div class="menu-box flex align-center">
                            <img :src="item.imgSrc ? getUrl(item.imgSrc) : defaultMenuIcon" alt="">
                            <div class="menu-name"> {{ item.name }}</div>
                        </div>
                    </el-menu-item>
                    <el-submenu
                        v-else
                        class="submenu-class"
                        :index="item.url"
                        :key="item.id"
                    >
                        <template slot="title">
                            <div class="menu-box flex align-center">
                                <img :src="item.imgSrc ? getUrl(item.imgSrc) : defaultMenuIcon" alt="">
                                <div class="menu-name"> {{ item.name }}</div>
                            </div>
                        </template>
                        <el-menu-item
                            v-for="child in item.children"
                            :index="`${item.url}/${child.url}`"
                            :key="child.id"
                            route
                            @click="menuItemClick(child)"
                        >
                            {{ child.name }}
                        </el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
    </section>
</template>

<script>
import constMenu from "@/assets/json/menu.json";
import {menuCookies, submenuCookies} from "@storage/cookies";

const defaultMenuIcon = require("@images/icon.png");
export default {
    name: "side-bar",
    data() {
        return {
            menuList: [],
            defaultMenuIcon
        };
    },
    computed: {
        activeMenu() {
            const route = this.$route;
            const {meta, path} = route;
            if (meta.activeMenu) return meta.activeMenu;

            return path;
        },
    },
    created() {
        //this.menuList = constMenu.filter(item => item.id !== 11 && item.id !== 9);
        let menuList = constMenu;
        let menuPathList = JSON.parse(menuCookies.get()).map(({url}) => url);
        let infacePathList = JSON.parse(submenuCookies.get()).map(({url}) => url);

        menuList = menuList.filter(({url}) => menuPathList.some(v => v === url));
        for (let item of menuList) {
            item.children = item.children.filter(({url}) => infacePathList.some(o => o === url));
        }
        this.menuList = menuList;
        console.log(menuList)
    },
    methods: {
        getUrl(url) {
            return require("@images/layout/" + url);
        },
        menuItemClick(data) {
            let {name, url} = data;
            url = url === '/home' ? 'Home' : url;
            this.$emit('addTab', {title: name, name: url});
        }
    }
};
</script>
<style lang="scss" rel="stylesheet/scss">
.el-menu {
    border: none;

    .menu-box {
        img {
            width: VW(17px);
            height: VH(20px);
        }

        .menu-name {
            padding-left: VW(15px);
        }
    }

    .el-menu-item,
    .el-submenu__title {
        padding-left: VW(50px) !important;
        background-color: transparent !important;
    }

    .el-submenu__title {
        height: VH(56px);
        line-height: VH(56px);
    }

    .el-menu-item {
        height: VH(46px);
        line-height: VH(46px);
    }

    .el-menu-item.is-active {
        background: linear-gradient(
                85deg, rgba(165, 171, 255, 0.22) 0%, rgba(31, 36, 106, 0.22) 96%, rgba(25, 30, 99, 0.22) 100%);

    }

    .el-submenu .el-menu-item {
        padding-left: VW(80px) !important;
        text-align: left;
    }

    .el-submenu__title i {
        color: #fff;
    }
}
</style>
<style lang="scss" scoped>
.side-bar {
    overflow: hidden;
    background: url("../../assets/images/layout/side_bg.png") no-repeat;
    background-size: 100% 100%;

    .side-bar-header {
        height: VH(155px);
        line-height: VH(200px);
        color: #fff;
        font-size: rem(30px);
        font-weight: bold;
        margin-bottom: VH(20px);

        img {
            display: block;
            margin: 0 auto;
            width: VW(100px);
        }
    }
}
</style>
