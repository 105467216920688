import { getByCodesParent } from "@/api/dictionary";

const state = {};

const mutations = {};

const actions = {
    async getDictionaryByCodeActions({ commit }, codes) {
        try {
            // 根据根据父类code获取所有子类 codes多个父类code，用逗号隔开
            const { list = [] } = await getByCodesParent(codes);
            return list;
        } catch (e) {
            console.error("获取字典", e);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
